import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import Accordion from 'react-bootstrap/Accordion';
import HtmlParser from "react-html-parser";
import {mediaBaseURL} from "../../utils/constant";

function Techsolution({data}) {
    return (
        <div className="tekrevol_gray_bg p-100">
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className="example" data-text={data?.tech_solution_body_txt}>
                            <h2>{checkData(data, 'tech_solution_title')}</h2>
                        </div>
                        <p>{checkData(data, 'tech_solution_description')}</p>
                    </Col>
                </Row>
                <Row className="tech_bg">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tech_tabs">
                        <Accordion defaultActiveKey="0">
                            {data?.tech_solution_tabs?.map((value, i) => (
                                <Accordion.Item eventKey={i}>
                                    <Accordion.Header>
                                        <img
                                            src={`${mediaBaseURL}${checkData(value, 'image')}`}
                                            className="img-fluid svg-icon"
                                            alt="tekRevol_img_title"/>
                                        {value.title}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {HtmlParser(checkData(value, 'description'))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}

                        </Accordion>
                    </Col>

                </Row>
            </Container>
        </div>
    );
}

export default Techsolution;