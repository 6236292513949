import React, {useEffect, useState} from 'react';
import Notebox from "./notebox";
import Contentsection from "./contentsection";
import Ourpractice from "./ourpractice";
import Ourfocus from "./ourfocus";
import Techsolution from "./techsolution";
import Techdevelopment from "./techdevelopment";
import Technology from "./technology";
import Question from "../services/question";
import Partner from "../home/partner";
import Featured from "../home/featured";
import Layout from "../layout/layout";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import Seo from "../layout/seo";
import {checkData} from "../../helper";
import Loader from "../components/Loader";
import Banner from "./banner";
import Breadcrumbs from "../components/breadcrumbs";


function Index({slug}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true)
        const response = await axios.get(`${baseURL}/solution/${slug}`);
        setUserData(response.data.data);
        setIsLoaded(false)
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, [slug]);


    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="TekRevol" link2="Products" link3={userData.name}/>
                    <Banner data={userData}/>
                    <Notebox data={userData}/>
                    <Contentsection data={userData}/>
                    <Ourpractice data={userData}/>
                    <Ourfocus data={userData}/>
                    <Techsolution data={userData}/>
                    <Techdevelopment data={userData}/>
                    <Technology data={userData}/>
                    <Question data={userData}/>
                    <Featured/>
                    <Partner/>
                </>
            }
        </Layout>
    );
}

export default Index;